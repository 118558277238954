import React from 'react';
import './FunctionCard.css'

function FunctionCard({image, title}) {
  return (
    <div className='functionCard'>
        <img src={image} alt="" />
        <h1>{title}</h1>
        
    </div>
  )
}

export default FunctionCard