import React from 'react';
import './Industry.css';
import { motion } from 'framer-motion';
import FunctionCard from './FunctionCard';


function Industry() {
  return (
    <motion.div initial={{ translateY: -40, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className='industry'>
      <h1 className='industry_title'>Industries we are focused on</h1>
      <span className='home_line'></span>

      <div className="home_row" >
        <FunctionCard
          image="./Images/card1.jpg"
          title="Defense Technology and Manufacturing"
        />
        <FunctionCard
          image="./Images/card2.jpg"
          title="Cyber Security Technology and Manufacturing"
        />
      

      </div>
      <div className="home_row" >
        <FunctionCard
          image="./Images/card4.jpg"
          title="Agriculture and Food Processing"
        />
        <FunctionCard
          image="./Images/card5.jpg"
          title="Robotics"
        />
        <FunctionCard
          image="./Images/card6.jpg"
          title="AI and Machine Learning"
        />

      </div>
      <div className="home_row" >
        <FunctionCard
          image="./Images/card7.jpg"
          title="Chip Designing and Manufacturing"
        />
        <FunctionCard
          image="./Images/card8.jpg"
          title="Augmented Reality/Virtual Reality/Metaverse"
        />
        <FunctionCard
          image="./Images/card9.jpg"
          title="Blockchain Technology and Web 3"
        />

      </div>
      <div className="home_row" >
        <FunctionCard
          image="./Images/card10.jpg"
          title="Renewable Energy"
        />
        <FunctionCard
          image="./Images/card11.jpg"
          title="Recycling"
        />
        <FunctionCard
          image="./Images/card12.jpg"
          title="Electric Mobility and Infrastructure"
        />

      </div>
      <div className="home_row" >
        <FunctionCard
          image="./Images/card13.jpg"
          title=" Education Infrastructure Online and Offline"
        />
        <FunctionCard
          image="./Images/card14.jpg"
          title="Drone Technology and Manufacturing"
        />
        <FunctionCard
          image="./Images/card15.jpg"
          title="Sustainable Home Designs/Community/Architecture"
        />

      </div>
      <div className="home_row" >
        <FunctionCard
          image="./Images/card16.jpg"
          title=" Aerospace/Satellite and Geographic Information Services"
        />
          <FunctionCard
          image="./Images/card3.jpg"
          title="Software Development"
        />
          <FunctionCard
          image="./Images/card17.jpg"
          title="Industry 4.0"
        />
      </div>

    </motion.div>
  )
}

export default Industry