import React from 'react';
import './About.css';
import { motion } from 'framer-motion';

function About() {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className='about'>

            {/* <h1>About Us</h1> */}


            <div className="about_content">
                <h1>Dares Technologies OPC Private Limited</h1>
                <span className='about_line'></span>
                <p>Dares is now a goverment recognised startup</p>
                <p>Dares Technologies offers research and development solutions for India's
                    major challenges in the domain of - Defence, Agriculture, Renewable Energy,
                    Recycling,Education, and Social Responsibility.
                </p>
                <p>
                    Dares envisions a future where India is Atma-Nirbhar in all domains. Dares
                    employs Multi-disciplinary Teams to offer innovative R&D solutions using
                    Technology for core industries. Solutions in these sectors showcase
                    pioneering research and strong technological insight from within the domestic
                    environment.
                </p>
                <p>We are not just an R&D company but also a data analytics and modelling
                    company which uses data to predict and model various scenarios for growth
                    and identifying challenges. </p>
                <p>
                    At Dares Technologies, we look forward to providing end to end solutions and
                    working hand in hand with our clients from the identification of a Problem to
                    the Commercialization of an Enterpris
                </p>
                <p>
                    The company also provides unique Information Communication Technology
                    (ICT) solutions which includes application development on a diverse range of
                    platforms including mobile devices.
                </p>
                <p>
                    We work closely with the dream team of Indian entrepreneurs, technologists
                    and academics to solve major challenges in India by implementing innovative
                    sustainable solutions. We provide our services to all sectors of the economy:
                    government organisations; corporate enterprises; industry associations and
                    think tanks and policy makers.
                </p>
                <p>
                    We want India to secure its rightful place among nations as a technology
                    powerhouse that conquers innovation at home with homegrown competencies
                    and industry expertise and to make Indian innovation an international
                    benchmark.
                </p>
            </div>

        </motion.div>
    )
}

export default About