import React from 'react';
import './Fotter.css'

function Fotter() {
  return (
    <div className='fotter'>
      <div className="fotter_container">

        <div className="fotter_tittle">
          <h1>#WEDARETOCARE</h1>
          <h2>DARES Technologies OPC Private Limited</h2>
  
        </div>
        <div className="fotter_content">
          <h1>CONTACT</h1>
          <p>PHONE: +9108047113328</p>
          <p>EMAIL: care@dares.co.in</p>

        </div>
      </div>
      <div className='fotter_right'>
        <p className='fotter_p'>© 2022 DARES Technologies OPC Private Limited. All rights reserved.</p>
      </div>

    </div>
  )
}

export default Fotter