import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { Spiral} from 'hamburger-react';




function Header() {
    const [showMediaIcons, setShowMediaIcons] = useState(false);
    const [isopen, setOpen] = useState(false)
    const [navbar, setNavbar] = useState(false);

    function MenuBtn(){
        setShowMediaIcons( showMediaIcons => !setShowMediaIcons);
        setOpen(isopen => !setOpen)
    }



    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }
    window.addEventListener("scroll", changeBackground);

    return (
        <div className={navbar ? 'header active' : "header"}>
            <Link to='/'>
                <h1 className="header__logo">DARES</h1>
                <p className="header__logoP">TECHNOLOGIES</p>
            </Link>
            <div onClick={MenuBtn} className={
                showMediaIcons ? "header__nav header__navPhone" : "header__nav"}>

                <Link to='/about' >
                    <div className="header__option">
                        <h1>About</h1>
                    </div>
                </Link>

                <Link to='/industry' >
                    <div className="header__option">
                        <h1 >Industries</h1>
                    </div>
                </Link>
                <Link to='/function' >
                    <div className="header__option">
                        <h1>Function</h1>
                    </div>
                </Link>


                <Link to='/contact' >
                    <div className="header__option">
                        <h1>Contact</h1>
                    </div>
                </Link>

            </div>

            <div className="hambuger-menu">
                <a href="#/" onClick={() => setShowMediaIcons(!showMediaIcons)}><Spiral  toggled={isopen} toggle={setOpen}/></a>
            </div>
        </div>
    )
}

export default Header
