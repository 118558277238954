import React from 'react';
import './Function.css';
import FunctionCard from './FunctionCard';
import { motion } from 'framer-motion';


function Function() {
  return (
    <motion.div initial={{translateY:-40, opacity:0}} animate={{translateY:0 , opacity:1}} exit={{opacity:0}} transition={{duration:0.5}} className='function'>
        <h1 className='function_title'>Function</h1>
        <div className="home_row">
            <FunctionCard
            image="./Images/functionCard.jpg"
            title="Identifying Industry Specific problems"
            />
            <FunctionCard
        image="./Images/functionCard2.jpg"
            title="Analyzing and Providing Implementable Solutions"
            />
         
        </div>
        <div className="home_row">
            <FunctionCard
              image="./Images/functionCard4.jpg"
            title=" Improving Employability Quotient for the nations Citizens"
            />
            <FunctionCard
              image="./Images/functionCard5.png"
            title="Risk Assessment and Mitigation for National Security."
            />
            <FunctionCard
              image="./Images/functionCard6.jpg"
            title=" Growth Projection of India in all domains"
            />
        </div>
        <div className="home_row">
            <FunctionCard
              image="./Images/functionCard7.jpg"
            title="Making India a Net Exporting country and reducing Imports."
            />
               <FunctionCard
              image="./Images/functionCard3.jpg"
            title="Research and Development on core Industry Problems and applying for IP and Patent for India"
            />
        </div>
        
    </motion.div>
  )
}

export default Function