import './App.css';
import Fotter from './Fotter';
import Header from './Header';
import Home from './Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contact from './Contact';
import About from './About';
import { AnimatePresence } from 'framer-motion';
import Function from './Function';
import Industry from './Industry';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Header />
      <AnimatePresence initial={true} exitBeforeEnter>
      <Routes  location={location} key={location.pathname}>
          <Route path="/" element={<Home/>}>
          </Route>
          <Route path="/contact" element={<Contact/>}>
          </Route>
          <Route path="/about" element={<About/>}>
          </Route>
          <Route path="/function" element={<Function/>}>
          </Route>
          <Route path="/industry" element={<Industry/>}>
          </Route>
        </Routes>
      </AnimatePresence>
      <Fotter/>
    </div>
  );
}

export default App;
