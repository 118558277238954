import React from 'react';
import './Banner.css';
import { motion } from 'framer-motion';

function Banner() {



  return (
    <motion.div exit={{ opacity: 0 }} transition={{ duration: 0.5 }} className='banner'>

      <div className='banner_img_container'>
        <motion.img initial={{ translateX: -100, opacity: 0 }} animate={{ translateX: 0, opacity: 1 }} transition={{ duration: 0.5 }} className="banner_img" src="./Images/Banner_img2.jpg" alt="" />
      </div>
      <div className="banner_content">
        <motion.h1 initial={{ translateY: -200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: 0.4 }}>Dares Technologies OPC </motion.h1>

        <motion.h1 initial={{ translateY: -200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: 0.4 }}>Private Limited</motion.h1>

        <motion.span initial={{ translateY: -200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} exit={{ translateY: 0, opacity: 0 }} transition={{ duration: 0.4 }} className='banner_line'></motion.span>

        <motion.p initial={{ translateY: -200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: 0.4 }}>Dares Technologies offers research and development solutions for India's major challenges in the domain of - Defence, Agriculture, Renewable Energy, Recycling,Education, and Social Responsibility..</motion.p>

        <a href="#dashboard"><motion.button initial={{ translateY: 200, opacity: 0 }} animate={{ translateY: 0, opacity: 1 }} transition={{ duration: 0.1 }} className='HomeNews_btn HomeNews_btn1'>Explore</motion.button></a>
      </div>

    </motion.div>
  )
}

export default Banner