import React from 'react';
import './Bar_Chart.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid,ResponsiveContainer, Legend, Tooltip } from 'recharts';


function Bar_Chart({data, title}) {



  return (
    <div className='chart' id='dashboard'>
  
      <div className="dash_board">

        <div className="bar_container">
          <p>{title}</p>
          <ResponsiveContainer width="100%" height="70%" >
          <BarChart
            width={600}
            height={300}
            data={data}
            margin={{
              top: 10,
              right: 20,
              left: 20,
              bottom: 0,
            }}
            barSize={40}
            >
            <XAxis dataKey="name" scale="point" padding={{ left: 50, right: 40 }} />

            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid stroke="#ececec"/>
            <Bar dataKey="percentage"  fill="rgb(57, 190, 62)" background={{ fill: 'rgb(58, 79, 80)' }} />
          </BarChart>
            </ResponsiveContainer>
        </div>
        <div className="map_container">
         <img src="./Images/gdp_map.png" alt="" />
        </div>

      </div>
    </div>
  )
}

export default Bar_Chart