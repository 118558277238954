import React, { useEffect, useState } from 'react';
import './Home.css'

import Banner from './Banner';
import Bar_Chart from './Bar_Chart';
import Aos from 'aos';
import "aos/dist/aos.css";

function Home() {

  const data = [
    { name: "2013", percentage: 5.5 },
    { name: "2014", percentage: 6.5 },
    { name: "2015", percentage: 7.4 },
    { name: "2016", percentage: 8 },
    { name: "2017", percentage: 8.3 },
    { name: "2018", percentage: 6.8 },
    { name: "2019", percentage: 6.5 },
    { name: "2020", percentage: 3.7 },
    { name: "2021", percentage: -6.6 },
    { name: "2022", percentage: 8.2 },
  ]
  const ManufacturingData = [
    { name: "2013", percentage: 15.25 },
    { name: "2014", percentage: 15.07 },
    { name: "2015", percentage: 15.58 },
    { name: "2016", percentage: 15.16 },
    { name: "2017", percentage: 15.2 },
    { name: "2018", percentage: 15.85 },
    { name: "2019", percentage: 13.33 },
    { name: "2020", percentage: 12.96 },
  ]
  const exportdata = [
    { name: "2013", percentage: 25.43 },
    { name: "2014", percentage: 22.97 },
    { name: "2015", percentage: 19.81 },
    { name: "2016", percentage: 19.16 },
    { name: "2017", percentage: 18.79 },
    { name: "2018", percentage: 19.95 },
    { name: "2019", percentage: 18.43 },
    { name: "2020", percentage: 18.08 },
  ]
  const importdata = [
    { name: "2013", percentage: 28.41 },
    { name: "2014", percentage: 25.95 },
    { name: "2015", percentage: 22.11 },
    { name: "2016", percentage: 20.92 },
    { name: "2017", percentage: 21.95 },
    { name: "2018", percentage: 23.66 },
    { name: "2019", percentage: 20.96 },
    { name: "2020", percentage: 18.39 },
  ]

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  const [active, setActive] = useState("firstcard")

  return (
    <div className='home'>
      <div className="Home_container">

        < Banner />
        <h1 id='dashboard' className='home_container_h1'>Growth Projections</h1>

        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="/Images/banner1.jpeg" alt="First slide" />
            </div>
            <div class="carousel-item">
            <img class="d-block w-100" src="/Images/banner2.jpeg" alt="First slide" />
            </div>
            <div class="carousel-item">
            <img class="d-block w-100" src="/Images/banner3.jpeg" alt="First slide" />
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>



        {/* <div className="dropdown show">
          <a className=" btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Growth rate
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a onClick={() => setActive("firstcard")} class="dropdown-item" >GDP</a>
            <a onClick={() => setActive("secondcard")} class="dropdown-item" >manufacturing</a>
            <a onClick={() => setActive("thirdcard")} class="dropdown-item" >export </a>
            <a onClick={() => setActive("fourthcard")} class="dropdown-item" >import</a>
          </div>
        </div> */}


        {/* {active === "firstcard" && <Bar_Chart
          data={data}
          title="GDP GROWTH RATE (INDIA)"
        />}
        {active === "secondcard" && <Bar_Chart
          data={ManufacturingData}
          title="MANUFACTURING GROWTH RATE (INDIA)"

        />}
        {active === "thirdcard" &&
          <Bar_Chart
            data={exportdata}
            title="EXPORT GROWTH RATE (INDIA)"

          />}
        {active === "fourthcard" &&
          <Bar_Chart
            data={importdata}
            title="IMPORT GROWTH RATE (INDIA)"
          />} */}



      </div>
    </div>
  )
}

export default Home