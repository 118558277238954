import React from 'react';
import './Contact.css';
import { motion } from 'framer-motion';
import emailjs from 'emailjs-com';

function Contact() {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_7v1atp3',
        "template_jwmohgm",
        e.target,
        "Ho-QrF1wV7MOrRx8C"
        ).then(res=>{
            console.log(res);
        }).catch(err=> console.log(err));
        e.target.reset()
    }



    return (
        <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} transition={{duration:0.5}} className='contact'>

            {/* <h1>Let's talk</h1> */}

            <div className="contact_container">

                <div className="contact_left">
                    <form  className='form' action="" onSubmit={sendEmail}>
                        <div className="input">
                            <input type="text" name='name' placeholder='Your name' required />
                        </div>
                        <div className="input">
                            <input type="email" name='user_email' placeholder='Your Email' />
                        </div>
                        <div className="input">
                            <textarea name='message' placeholder='Share your thoughts' cols="22" rows="2" required></textarea>
                        </div>
                        <input type='submit' value="SHARE YOUR MESSAGE" className='contact_btn'/>
                    </form>

                </div>

                <div className="contact_right">
                    <h2>We will love to hear from you </h2>
                    <span className='contact_line'></span>
                    <p>Dares Technologies OPC Private Limited</p>
                    <div>
                    <p className='address'>ADRESS: 351,3rd & 4th Floor Salarpuria Tower-1,Hosur Road, Near Forum Mall,Koramangala 7th Block, Bengaluru Bangalore KA 560095 IN</p>
                    </div>
                </div>
             
            </div>

        </motion.div>
    )
}

export default Contact